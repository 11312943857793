.carousel .control-dots {
    margin: 700px auto;
  }
  
  .carousel .control-dots .dot {
    background: black;
  }
  
  .carousel {
    height: 1000px;
  }
  
  @media screen and (max-width: 1532px) {
    .carousel {
      height: 850px;
    }
    .carousel .control-dots {
      margin: 400px auto;
    }
  }
  @media screen and (max-width: 1279px) {
    .carousel {
      height: 100%;
    }
    .carousel .control-dots {
      margin: 200px auto;
    }
  }
  @media screen and (max-width: 1023px) {
    .carousel {
      height: 470px;
    }
  }
  @media screen and (max-width: 767px) {
    .carousel {
      height: 400px;
    }
    .carousel .control-dots {
      margin: 100px auto;
    }
  }
  @media screen and (max-width: 639px) {
    .carousel {
      height: 200px;
    }
    .carousel .control-dots {
      margin: 0px auto;
    }
  } ;

/* Custom Carousel */
.container-slider {
    height: 900px;
    position: relative;
    overflow: hidden;
    margin-top: -290px;
  }
  
  .slide {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    transition: opacity ease-in-out 0.4s;
  }
  .slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .active-anim {
    opacity: 1;
  }
  
  .btn-slide {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #f1f1f1;
    border: 1px solid rgba(34, 34, 34, 0.287);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .btn-slide img {
    width: 25px;
    height: 25px;
    pointer-events: none;
  }

  .container-dots {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
  }
  .dot {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 3px solid #f1f1f1;
    margin: 0 5px;
    background: #f1f1f1;
  }
  .dot.active {
    background: rgb(32, 32, 32);
  }

  @media screen and (max-width: 1024px) {
    .container-slider {
      height: 1200px;
    }
  }

  @media screen and (max-width: 768px) {
    .container-slider {
      height: 1100px;
    }
  }
  
  @media screen and (max-width: 640px) {
    .container-slider {
      height: 900px;
    }
  }

  @media screen and (max-width: 500px) {
    .container-slider {
      height: 750px;
    }
  }